<template>
  <div class="w-full bg-white rounded-lg flex flex-col gap-y-0">
    <!-- --------------------HEADER-------------------- -->
    <header
      class="
        h-[105px]
        flex flex-row
        gap-x-4
        items-center
        px-8
        border-0 border-b border-solid border-gray-200
      "
    >
      <!-- IF NOT OPEN MESSAGE  -->
      <div class="flex-1 flex items-center gap-x-2 w-full">
        <div
          class="
            h-[55px]
            w-[55px]
            overflow-hidden
            rounded-full
            border border-solid border-gray-200
          "
        >
          <img
            :src="$checkAvatar(currentConversation.image)"
            onerror="javascript:this.src='/no-avatar.png'"
            :alt="currentConversation.title"
            class="h-full w-full rounded-full object-cover"
          />
        </div>

        <h1 class="text-xl font-EffraM">
          {{ currentConversation.title }}
        </h1>
      </div>
      <!-- Video Call -->
      <span
        @click="onVideoCallClicked()"
        class="
          text-black
          rounded-full
          flex
          items-center
          justify-center
          cursor-pointer
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M23 7l-7 5 7 5V7zM14 5H3c-1.105 0-2 .895-2 2v10c0 1.105.895 2 2 2h11c1.105 0 2-.895 2-2V7c0-1.105-.895-2-2-2z"
          />
        </svg>
      </span>
    </header>
    <!-- --------------------END HEADER-------------------- -->

    <!-- --------------------CONTENT-------------------- -->
    <main
      class="
        flex-1
        max-h-[450px]
        overflow-hidden overflow-y-auto
        py-3
        px-8
        flex flex-col
        gap-y-4
      "
      id="contentMessages"
    >
      <div
        class="min-h-[300px] flex items-center justify-center overflow-hidden"
        v-if="!messages.length"
      >
        <img src="/svg/request-pana.svg" style="height: 300px" />
      </div>
      <div v-for="(message, ii) in messagesConversation" :key="ii">
        <!-- FROM -->
        <div class="flex justify-start" v-if="!$auth.isSameUser(message.owner)">
          <div
            class="
              bg-dokBlue-lighter
              p-4
              font-EffraR
              text-black text-base
              rounded-lg
              min-w-200
            "
          >
            {{ message.body || "--" }}
            <p class="text-sm flex justify-end text-gray-500">
              {{ moment()(message.created_at) | moment("from", "now") }}
            </p>
          </div>
        </div>
        <!-- TO -->
        <div class="flex justify-end" v-if="$auth.isSameUser(message.owner)">
          <div
            class="
              bg-dokBlue-lighterrr
              p-4
              font-EffraR
              text-black text-base
              rounded-lg
              min-w-200
            "
          >
            <span v-if="message.body">{{ message.body }}</span>
            <audio controls v-if="message.category === 'PLAIN_AUDIO'">
              <source
                :src="`https://api.dok.ma/api/v1/download/document/${message.fileId}`"
                type="audio/aac"
              />
              Your browser does not support the audio element.
            </audio>
            <p class="text-sm flex justify-end text-gray-500">
              {{ moment()(message.created_at) | moment("from", "now") }}
            </p>
          </div>
        </div>
      </div>
    </main>
    <!-- --------------------END CONTENT-------------------- -->

    <!-- --------------------FOOTER-------------------- -->
    <div
      class="
        h-[112px]
        flex flex-row
        items-center
        gap-x-6
        px-8
        border-0 border-t border-solid border-gray-200
      "
    >
      <input
        type="text"
        placeholder="Ecrire un message..."
        class="
          flex-1
          h-[48px]
          rounded-r-lg
          border border-solid border-[#d7dde5]
          px-3
        "
        v-model="message.body"
        v-on:keyup.13="sendMessge"
      />
      <dok-button
        bg="blue"
        size="lg"
        custom-class="flex items-center gap-x-2 px-8"
        @click.native="sendMessge"
      >
        <svg
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 2 11 13M22 2l-7 20-4-9-9-4 20-7z"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        Envoyer
      </dok-button>
    </div>
    <!-- --------------------END FOOTER-------------------- -->
  </div>
</template>

<script>
import moment from "moment";
import FirebasePlugin from "@/plugins/firebase.js";
import { sortBy } from "lodash";
import { EventBus } from "@/event-bus";

export default {
  props: ["currentConversation", "onVideoCallClicked"],
  data() {
    return {
      message: {
        body: null,
      },
      messages: [],
    };
  },
  computed: {
    messagesConversation() {
      return sortBy(this.messages, function (data) {
        return data.created_at;
      });
    },
  },
  created() {
    this.listenForMessages();
  },
  watch: {
    currentConversation: function (newVal, oldVal) {
      if (newVal._id !== oldVal._id) {
        this.listenForMessages();
      }
    },
  },
  methods: {
    moment() {
      return moment;
    },
    scrollToBottom() {
      let contentMessagesElement = document.getElementById("contentMessages");
      contentMessagesElement.scrollTop = contentMessagesElement.scrollHeight;
    },
    listenForMessages() {
      this.messages = [];
      FirebasePlugin.onNewMessage(this.currentConversation.idHash, {
        name: this.currentConversation.idHash,
        handler: this.onNewMessage,
      });
    },
    onNewMessage(snapshot) {
      const data = snapshot.val();
      this.currentConversation.lastMessage.body = data.body;

      this.$nextTick(() => {
        this.messages.push(data);

        this.$nextTick(() => this.scrollToBottom());
      });
    },
    async sendMessge() {
      event.preventDefault();

      if (!this.message.body || !this.message.body.replace("\n", "")) return;

      this.$vs.loading();

      const messagePush = {
        body: this.message.body,
        category: "PLAIN_TEXT",
        conversation: this.currentConversation._id,
        created_at: new Date(),
        deleted: false,
        owner: this.$auth.getUserInfo()._id,
        status: "SENT",
        updated_at: new Date(),
      };

      this.$store.dispatch("chat/SEND_MESSAGE", {
        data: messagePush,
        onData: (data) => {
          if (data.status === "SENT") {
            this.message.body = "";

            EventBus.$emit("reloadLastMessage");

            this.$nextTick(() => {
              this.scrollToBottom();
              this.$vs.loading.close();
            });
          }
        },
      });
    },
  },
};
</script>
